import { Suspense, lazy, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { setFetcherAuthToken } from "src/apis/fetcher";
import { Layout } from "src/components/ui";
import { Fallback } from "src/components/ui/Fallback";
import { ROUTES } from "src/constants/routes";
import { useRootStore } from "src/store";

const Login = lazy(() => import("src/pages/login"));
const Home = lazy(() => import("src/pages/home"));
const Users = lazy(() => import("src/pages/users"));
const Courses = lazy(() => import("src/pages/courses"));
const Subscriptions = lazy(() => import("src/pages/subscriptions"));
const OfflineSubscriptions = lazy(() => import("src/pages/offline-subscriptions"));
const Gears = lazy(() => import("src/pages/gears"));
const Contact = lazy(() => import("src/pages/contact"));
const Lessons = lazy(() => import("src/pages/lessons"));
const Notfound = lazy(() => import("src/pages/404"));

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <Suspense fallback={<Fallback />}><Login /></Suspense>,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: ROUTES.HOME,
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES.USERS,
        index: true,
        element: <Users />,
      },
      {
        path: ROUTES.COURSES,
        index: true,
        element: <Courses />,
      },
      {
        path: ROUTES.LESSONS,
        index: true,
        element: <Lessons />,
      },
      {
        path: ROUTES.SUBSCRIPTIONS,
        index: true,
        element: <Subscriptions />,
      },
      {
        path: ROUTES.OFFLINE_SUBSCRIPTIONS,
        index: true,
        element: <OfflineSubscriptions />,
      },
      {
        path: ROUTES.GEARS,
        index: true,
        element: <Gears />,
      },
      {
        path: ROUTES.CONTACT,
        index: true,
        element: <Contact />,
      },
    ],
  },
  {
    path: "*",
    element: <Notfound />,
  },
]);

export function Router() {
  const token = useRootStore(state => state.token);
  useEffect(() => {
    if (token) setFetcherAuthToken(token);
  }, [token]);
  return <RouterProvider router={router} />;
}
