import { useRootStore } from "..";

export const useUser = () =>
  useRootStore(
    /**
     *
     * @param {import("..").RootState} state
     * @returns
     */
    (state) => state.user
  );
