import axios from "axios";
import { ENVS } from "src/constants";
import { useRootStore } from "src/store";

useRootStore.subscribe(
  /**
   *
   * @param {import("src/store").RootState} state
   * @returns
   */
  (state) => state.token,
  setFetcherAuthToken
);

export const fetcher = axios.create({
  baseURL: ENVS.SERVER,
});

fetcher.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    if (error?.response?.data?.status === "UNAUTHORIZED") {
      useRootStore.getState().setToken("");
    }
    return Promise.reject(error?.response?.data);
  }
);

/**
 *
 * @param {string} token
 */
export function setFetcherAuthToken(token) {
  setFetcherHeaders("Authorization", `Bearer ${token}`);
}

function setFetcherHeaders(key, value) {
  fetcher.defaults.headers.common[key] = value;
}
