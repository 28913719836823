import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import { useLogout } from "src/store/actions/user";
import { Button, Layout } from "antd";
import { useUser } from "src/store/states/user";

const { Header: Head } = Layout;

const Header = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const { username } = useUser();

  function onClick() {
    logout();
    navigate(ROUTES.LOGIN, { replace: true });
  }

  return (
    <Head className="bg-white border-0 border-b border-gray-200 border-solid flex px-3 items-center justify-between">
      <div>
        <img src="/logo.png" className="w-14 aspect-square" alt="logo" />
        <span>{username}</span>
      </div>
      <div className="space-x-3">
        <Button onClick={onClick} className="font-semibold" type="link">
          {"Logout"}
        </Button>
      </div>
    </Head>
  );
};

export default Header;
