import { UploadOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { SERVER } from "src/constants/envs";

/**
 * 
 * @param {{
 * placeholder?:string;
 * type:"image"|"video";
 * aspectRatio?:string;
 * }&React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} props 
 * @returns 
 */
export function FileInput({ type, placeholder, onChange, aspectRatio, ...props }) {

  const [fileURL, setFileURL] = useState(props.value ? `${SERVER}/${props.value}` : "");
  const accept = useMemo(() => type === "image" ? ".jpg,.jpeg,.png" : ".mp4", [type]);

  return <label {...props} >
    <input type="file" accept={accept} className="w-0 h-0" onChange={e => {
      const file = e.target.files[0];
      setFileURL(file ? URL.createObjectURL(file) : "");
      onChange(e.target.files[0]);
    }} />
    {
      fileURL ? (type === "image" ? <img alt="file input" src={fileURL} className={`w-full object-contain bg-gray-300 rounded-md ${aspectRatio || "aspect-video"}`} /> :
        <video className={`w-full bg-gray-300 rounded-md ${aspectRatio || "aspect-video"} `}>
          <source src={fileURL} />
        </video>) :
        <div className="rounded-md border border-solid border-gray-200 p-2 px-3 inline-flex gap-3 items-center"><UploadOutlined />{placeholder ?? "Select File"}</div>
    }
  </label>;
}
