import {
  HomeFilled,
  UserOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  MailOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import React from "react";

export const ROUTES = {
  LOGIN: "/login",
  HOME: "/home",
  USERS: "/users",
  COURSES: "/courses",
  SUBSCRIPTIONS: "/subscriptions",
  OFFLINE_SUBSCRIPTIONS: "/offline-subscriptions",
  GEARS: "/gears",
  CONTACT: "/contact-us",
  LESSONS: "/lessons",
};

/**
 * @type {Omit<typeof ROUTES,"LOGIN">}
 */
export const MENU = {
  // HOME: ROUTES.HOME,
  USERS: ROUTES.USERS,
  COURSES: ROUTES.COURSES,
  LESSONS: ROUTES.LESSONS,
  SUBSCRIPTIONS: ROUTES.SUBSCRIPTIONS,
  OFFLINE_SUBSCRIPTIONS: ROUTES.OFFLINE_SUBSCRIPTIONS,
  GEARS: ROUTES.GEARS,
  CONTACT: ROUTES.CONTACT,
};

/**
 * @type {{[x in keyof typeof MENU]:string}}
 */
export const MENU_LABEL = {
  HOME: "HOME",
  USERS: "USERS",
  COURSES: "COURSES",
  LESSONS: "LESSONS",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  OFFLINE_SUBSCRIPTIONS: "OFFLINE SUBSCRIPTIONS",
  GEARS: "GEARS",
  CONTACT: "CONTACT",
};

/**
 * @type {{[x in keyof typeof MENU]:React.ReactNode}}
 */
export const MENU_ICON = {
  HOME: <HomeFilled />,
  USERS: <UserOutlined />,
  COURSES: <VideoCameraOutlined />,
  LESSONS: <VideoCameraOutlined />,
  SUBSCRIPTIONS: <UsergroupAddOutlined />,
  OFFLINE_SUBSCRIPTIONS: <UsergroupAddOutlined />,
  GEARS: <SettingOutlined />,
  CONTACT: <MailOutlined />,
};
