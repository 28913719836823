import { Layout as L } from "antd";
import { Suspense, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import { useRootStore } from "src/store";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { Fallback } from "./Fallback";
const { Content, Sider } = L;

export function Layout() {
  const [collapsed, setCollapsed] = useState(false);
  const token = useRootStore(state => state.token);
  const location = useLocation();

  if (!token)
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;

  return (
    <L className="h-full">
      <Header />
      <L className="site-layout">
        <Sider
          className="bg-white h-full"
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
        >
          <SideMenu />
        </Sider>
        <Content style={{ margin: "0 16px" }}>
          <Suspense fallback={<Fallback />}>
            <Outlet />
          </Suspense>
        </Content>
      </L>
    </L>
  );
}
