import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU, MENU_ICON, MENU_LABEL } from "src/constants/routes";

const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const keys = Object.keys(MENU);
  return (
    <Menu
      defaultSelectedKeys={[location.pathname]}
      className="shadow-lg capitalize h-full"
      items={keys.map((key) => ({
        key: MENU[key],
        icon: MENU_ICON[key],
        label: MENU_LABEL[key],
        onClick: () => navigate(MENU[key]),
      }))}
    />
  );
};

export default SideMenu;
