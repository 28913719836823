import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "./routes/root";
import { notification } from "antd";

const client = new QueryClient({
  defaultOptions: {
    mutations: {
      onError(err) {
        notification.error({ message: err?.message });
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={client}>
      <Router />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
