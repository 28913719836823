import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

/**
 * @typedef {{
 * user:User;
 * token:string;
 * pageSize:number;
 * }} DefaultState
 *
 * @typedef {{
 * setUser:(user:User)=>void;
 * setToken:(token:string)=>void;
 * logout:()=>void;
 * setPageSize:(size:number)=>void;
 * }} Actions;
 *
 * @typedef {DefaultState&Actions} RootState
 */

/**
 * @type {DefaultState}
 */
const initialState = {
  user: null,
  token: null,
  pageSize: 10,
};

/**
 * @type {import("zustand").UseBoundStore<import("zustand").StoreApi<RootState>>}
 */
export const useRootStore = create(
  devtools(
    subscribeWithSelector(
      persist(
        immer((set, get) => ({
          ...initialState,
          setUser(user) {
            set((state) => {
              state.user = user;
            });
          },
          setToken(token) {
            set((state) => {
              state.token = token;
            });
          },
          logout() {
            set((state) => {
              state.user = null;
              state.token = null;
            });
          },
          setPageSize: (size) => set({ pageSize: size }),
        })),
        {
          name: "yoga-storage",
        }
      )
    ),
    { name: "yoga-store" }
  )
);
