import { useRootStore } from "..";

export function useSetUser() {
  return useRootStore(
    /**
     *
     * @param {import("..").RootState} state
     * @returns
     */
    (state) => state.setUser
  );
}
export const useLogout = () =>
  useRootStore(
    /**
     *
     * @param {import("..").RootState} state
     * @returns
     */
    (state) => state.logout
  );
